window.Aurora = angular.module('Aurora', ['ngResource', 'ngSanitize'])

Aurora.config(['$httpProvider', ($httpProvider)->
  $httpProvider.defaults.headers.common['X-CSRF-Token'] = document.getElementsByName('csrf-token')[0].content
  $httpProvider.defaults.headers.common['Accept'] = 'application/json'
])

#707 157 404 19.428571428571427
# 189
Aurora.controller('HomeController', ['$scope', '$http', '$timeout', ($scope, $http, $timeout)->



  $scope.init = ()->
    $scope.current_indicatif_picture = "france"
    $scope.commercial_contact = {from: "home_page", current_indicateur: "+33", format: "a4", cover: "souple", file_ready: "non"}
    $scope.footer_contact = {from: "footer_home_page", organization: "Contact depuis le footer sur site vitrine", current_indicateur: "+33"}
    screen_height = window.innerHeight
    
    $timeout( () ->
      devis_height = ((screen_height - $("header").height() - $('.border_panel').height() - 110) / $('.border_panel .your_project_item').length)
      if devis_height > 35
        devis_height = 35
      else if devis_height < 6
        devis_height = 6
      #alert(screen_height + " "+$("header").height() + " " + $('.border_panel').height()+ " " + devis_height)
      $('.border_panel .your_project_item').css("marginBottom", devis_height + "px")
      $("#carouselExampleIndicators").height($('.border_panel').height() - 44)
      if $("#make_yearbook").length
        $(".carousel-item").height($('.border_panel').height() - 44)
        if $(".for_entreprise").length
          if $("body").width() < 501
            $(".carousel-item").height($(".carousel-item").width() / 1.333333)
            $("#carouselExampleIndicators").height($(".carousel-item").width() / 1.333333)
          else
            $(".carousel-item").height($('.border_panel').height() - 10)
            $(".carousel-item").width(($('.border_panel').height() - 10)*1.33333)
            $(".carousel-item").css("marginLeft", (($('.carousel-inner').width() - $(".carousel-item").width() ) / 2) + "px")
      else
        $("#carouselExampleIndicators").height($('.border_panel').height() + 150)
        $(".carousel-item").height($('.border_panel').height() + 150)
    , 100)
  
  $scope.change_inficatif = (id, picture) ->
    $scope.current_indicatif_picture = picture
    $scope.commercial_contact.current_indicateur = id
    $scope.footer_contact.current_indicateur = id
  
  $scope.close_modal_yearbook_budget = () ->
    $scope.modal_yearbook_budget.hide()
    
  $scope.open_yearbook_budget_modal = (type) ->
    $scope.type_budget = type
    $scope.show_picture_budget = 1
    $scope.modal_yearbook_budget = new bootstrap.Modal(document.getElementById('modal_yearbook_budget'), {})
    $scope.modal_yearbook_budget.show()
  
  $scope.yearbook_budget_modal_next_picture = (type) ->
    if type == "+"
      $scope.show_picture_budget = $scope.show_picture_budget + 1
      if ($scope.type_budget == "classic" && $scope.show_picture_budget == 3) || $scope.show_picture_budget == 4 
        $scope.show_picture_budget = 1
    else if type == "-"
      $scope.show_picture_budget = $scope.show_picture_budget - 1
      if $scope.type_budget == "classic" && $scope.show_picture_budget == 0
        $scope.show_picture_budget = 2
      else if $scope.show_picture_budget == 0
        $scope.show_picture_budget = 3
  $scope.open_download_ultimate_yearbook_modal = () ->
    $scope.sended_ultimate_yearbook = false
    $scope.sending_ultimate_yearbook = false
    $scope.email_ultimate_yearbook = ""
    $scope.modal_error_commercial_contact = new bootstrap.Modal(document.getElementById('modal_download_ultimate_yearbook'), {})
    $scope.modal_error_commercial_contact.show()
    $.post("/send_ultimate_yearbook", {email: ""})
      .then (response) ->
        # do nothing
  
  $scope.send_ultimate_yearbook = () ->
    $scope.sending_ultimate_yearbook = true
    if $scope.email_ultimate_yearbook != ""
      $.post("/send_ultimate_yearbook", {email: $scope.email_ultimate_yearbook})
        .then (response) ->
          console.log("oki!!!!!!!")
          $scope.sended_ultimate_yearbook = true
          $scope.sending_ultimate_yearbook = false
          $scope.apply_scope()
  
  $scope.close_ultimate_yearbook = () ->
    $scope.modal_error_commercial_contact.hide()
  
  $scope.apply_scope = () ->
    try
      if !$scope.$$phase
        $scope.$apply()
    catch e
      #error
      
  $scope.send_commercial_contact = ()->
    if $scope.commercial_contact.where_find_us != "" && $scope.commercial_contact.where_find_us != undefined
      alert("Êtes-vous un robot ?")
    else
      if $scope.commercial_contact.email == undefined || $scope.commercial_contact.phone_number == undefined || $scope.commercial_contact.name == undefined || $scope.commercial_contact.organization == undefined
        $scope.modal_error_commercial_contact = new bootstrap.Modal(document.getElementById('modal_error_commercial_contact'), {})
        $scope.modal_error_commercial_contact.show()
      else
        $.post("/send_commercial_contact", {commercial_contact: $scope.commercial_contact})
          .then (response) ->
            if $scope.modal_devis != undefined
              $scope.modal_devis.hide()
            $scope.modal_thanks_commercial_contact = new bootstrap.Modal(document.getElementById('modal_thanks_commercial_contact'), {})
            $scope.modal_thanks_commercial_contact.show()
            $scope.commercial_contact = {from: "home_page", current_indicateur: "+33", format: "a4", cover: "souple", file_ready: "non"}
  
  $scope.send_footer_contact = () ->
    if $scope.footer_contact.where_find_us != "" && $scope.footer_contact.where_find_us != undefined
      alert("Êtes-vous un robot ?")
    else
      if $scope.footer_contact.email == undefined || $scope.footer_contact.phone_number == undefined || $scope.footer_contact.name == undefined
        $scope.modal_error_commercial_contact = new bootstrap.Modal(document.getElementById('modal_error_commercial_contact'), {})
        $scope.modal_error_commercial_contact.show()
      else
        $.post("/send_commercial_contact", {commercial_contact: $scope.footer_contact})
          .then (response) ->
            $scope.modal_thanks_commercial_contact = new bootstrap.Modal(document.getElementById('modal_thanks_commercial_contact'), {})
            $scope.modal_thanks_commercial_contact.show()
            $scope.footer_contact = {from: "footer_home_page", organization: "Contact depuis le footer sur site vitrine", current_indicateur: "+33"}
  
  $scope.open_devis_modal = ()->
    $scope.modal_devis = new bootstrap.Modal(document.getElementById('modal_devis'), {})
    $scope.modal_devis.show()
    
    $timeout( () ->
      devis_height = ((screen_height - $("header").height() - $('.border_panel').height() - 150) / $('.border_panel .your_project_item').length)
      if devis_height > 25
        devis_height = 25
      $('.border_panel .your_project_item').css("marginBottom", devis_height + "px")
    , 100)
  $scope.open_entreprise_modal = ()->
    $scope.entreprise_modal = new bootstrap.Modal(document.getElementById('modal_entreprise'), {})
    $scope.entreprise_modal.show()
  
  $scope.close_commercial_contact_modal = ()->
    $scope.modal_thanks_commercial_contact.hide()
    
  $scope.close_commercial_contact_error_modal = ()->
    $scope.modal_error_commercial_contact.hide()
        
])