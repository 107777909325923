$(document).on 'ready' , ->
      
  $('.yearbook_turn, .yearbook_turn_small').each ->
    el = $(this).attr('yearbook')
    $(this).magnificPopup
      items: {src: '#flipbook_container_'+el}
      type: 'inline'
      closeBtnInside: false
      closeOnBgClick: false
      fixedContentPos: true
      callbacks: {
        open: () ->
          $('#flipbook_container_'+el + ' img').each ->
            $(this).attr('src', $(this).data('src2'))
            $(this).removeAttr('data-src2')
      }

  
  
