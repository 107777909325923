

#= require blueimp-file-upload/js/vendor/jquery.ui.widget
#= require blueimp-file-upload/js/jquery.fileupload
#= require blueimp-file-upload/js/jquery.iframe-transport

#= require index/turnjs
#= require jquery.magnific-popup

$(document).on('ready page:load', ->

  ###################################
  $(".share_yearbook").each ->
    $(this).magnificPopup
      items: {src: '.share_panel'}
      type: 'inline'
      closeBtnInside: false
      closeOnBgClick: false
      zoom: {
        enabled: true,
        duration: 300
      }

  $(".show_summary").each ->
    $(this).magnificPopup
      items: {src: '.summary'}
      type: 'inline'
      closeBtnInside: false
      closeOnBgClick: false

  $('.show_diaporama').each ->
    $(this).magnificPopup
      items: {src: '.diaporama'}
      type: 'inline'
      closeBtnInside: false
      closeOnBgClick: false
      callbacks: {
        open: () ->
          first_picture = $(".flipbook_container .hard img")[0]
          #ratio = $(first_picture).prop('naturalWidth') / $(first_picture).prop('naturalHeight')
          ratio = parseInt($(".flipbook_container").attr('width')) / parseInt($(".flipbook_container").attr('height'))
          $('.diaporama .preview').each ->
            $(this).height((100 / ratio) + "px")
          $('.diaporama img').each ->
            $(this).attr('src', $(this).data('src'))
      }

  $('.flipbook_container').each ->
    yearbook_element_id = $(this).attr('id')
    flipbook = $('#' + yearbook_element_id + ' .flipbook')
    move_page_from = ""
    zoom = 1
    tooltip = $('<div id="tooltip" />').css({position: 'absolute', bottom: 30, left: -20}).hide();
    $('#' + yearbook_element_id).find('div .slider').slider(
      value: 1
      min: 1
      max: flipbook.attr('nb_page')
      step: 1
      create: (event, ui) ->
        tooltip.html("<img src='" + jQuery.parseJSON($('#'+yearbook_element_id).find('.list_pages').html())[0] + "' style='width:100px' />")
      slide: (event, ui) ->
        move_page_from = "slider"
        tooltip.html("<img src='" + jQuery.parseJSON($('#'+yearbook_element_id).find('.list_pages').html())[(ui.value-1)] + "' style='width:100px' />")
        return
    ).find('.ui-slider-handle').append(tooltip).hover (->
      tooltip.show()
      return
    ), ->
      tooltip.hide()
      return
  
    first_picture = $(".flipbook_container .hard img")[0]
    ratio = parseInt($(this).attr('width')) / parseInt($(this).attr('height'))
    width_container = $('.flipbook_container').innerWidth()
    if width_container == 0
      width_container = $('body').innerWidth() - 10
    if ( width_container > 0 && ((width_container/2) / ratio) < (window.innerHeight - 140) )
      width = width_container
      height = (width_container/2) / ratio
    else if ratio > 1
      width = window.innerWidth - 50
      height = width / ratio / 2
    else
      height = window.innerHeight - 140
      width = height*ratio*2
    flipbook.turn
      autoCenter: false
      width: width
      height: height
      disable: true
      when:
        #start: (event, pageObject, corner) ->
        #  if (corner!=null)
        #    $(this).turn('data').hover = true;
        #    #return event.preventDefault();
        #turning: (e, page, view) ->
        #  if ($(this).turn('data').hover)
        #    $(this).turn('data').hover = false;
        #    e.preventDefault();
        turned: (e, page) ->
          if page > 1 && (page%2 == 0)
            if (page) == 2
              $('#' + yearbook_element_id + ' .page-number').val('II')
            else if parseInt(flipbook.attr('nb_page')) == page
              $('#' + yearbook_element_id + ' .page-number').val('IV')
            else if (parseInt(flipbook.attr('nb_page'))-2) == page
              $('#' + yearbook_element_id + ' .page-number').val('III')
            else
              $('#' + yearbook_element_id + ' .page-number').val(page-2)
          else
            if page == 1
              $('#' + yearbook_element_id + ' .page-number').val('I')
            else if page == 3
              $('#' + yearbook_element_id + ' .page-number').val('II')
            else if parseInt(flipbook.attr('nb_page')) == page
              $('#' + yearbook_element_id + ' .page-number').val('IV')
            else if (parseInt(flipbook.attr('nb_page'))-1) == page
              $('#' + yearbook_element_id + ' .page-number').val('III')
            else
              $('#' + yearbook_element_id + ' .page-number').val(page-2)
          flipbook.find('img').each ->
            if $(this).data('src') != ""
              $(this).attr('src', $(this).data('src'))
            if $(this).data('src2') != ""
              if $(".mfp-ready").length
                $(this).attr('src', $(this).data('src2'))
                $(this).removeAttr("data-src2");
          if (move_page_from != "slider")
            $('#' + yearbook_element_id).find('div .slider').slider( "value", page );
            
    #flipbook.turn("disable", true);

    $('#' + yearbook_element_id).find('div .slider').on( "slidechange", ( event, ui ) ->
      flipbook.turn('page', ui.value)
      tooltip.html("<img src='" + jQuery.parseJSON($('#'+yearbook_element_id).find('.list_pages').html())[(ui.value-1)] + "' style='width:100px' />")
      setTimeout( () ->
        move_page_from = ""
      , 700);
      
      return false;
    )

  
    $('#' + yearbook_element_id + ' .number-pages').html(parseInt(flipbook.attr('nb_page'))-4)
    $('#' + yearbook_element_id + ' .page-number').keydown( (e) ->
      if (e.keyCode == 13)
        go_to_page = $('#' + yearbook_element_id + ' .page-number').val()
        if go_to_page == "I"
          go_to_page = 1
        else if go_to_page == "II"
          go_to_page = 2
        else if go_to_page == "IV"
          go_to_page = parseInt(flipbook.attr('nb_page'))
        else if go_to_page == "III"
          go_to_page = parseInt(flipbook.attr('nb_page')) - 1
        else
          go_to_page = parseInt(go_to_page) + 2
        flipbook.turn('page', go_to_page)
    )
    $(window).bind('keydown', (e) ->
      if($("input,textarea").is(":focus"))
        #do nothing
      else
        if (e.keyCode==37)
          flipbook.turn('previous');
        else if (e.keyCode==39)
          flipbook.turn('next');
    )

    $(".more_zoom").on('click', (event)->
      event.preventDefault()
      zoom = zoom + 0.5
      flipbook.turn("zoom", zoom)
      $(".less_zoom").removeClass('disabled_button')
      if (zoom == 2)
        $(".more_zoom").addClass('disabled_button')
    )

    $(".less_zoom").on('click', (event)->
      event.preventDefault()
      zoom = zoom - 0.5
      flipbook.turn("zoom", zoom)
      $(".more_zoom").removeClass('disabled_button')
      if (zoom == 0.5)
        $(".less_zoom").addClass('disabled_button')
    )

    $('body').on('click', '.go_to_page', (event)->
      event.preventDefault();
      flipbook.turn('page', $(this).attr('goto'))
      magnificPopup = $.magnificPopup.instance; 
      magnificPopup.close();
    )
    
    #$('.flipbook_container').on('click', (event)->
    #  event.preventDefault();
    #  page_number = $(event.target).parents(".page-wrapper").attr("page")
    #  if page_number%2 == 0
    #    flipbook.turn("previous");
    #  else
    #    flipbook.turn("next");
    #)

    $('.add_bookmark').on('click', (event)->
      event.preventDefault();
      $.get( "/yearbooks/add_to_bookmark?yearbook_id=" + $(this).attr('yearbook_id') + "&page_id=" + flipbook.turn("page"))
        .success (data) ->
          $('#bookmark_'+yearbook_element_id).html('')
          $.each(data.bookmarks, ( index, value ) ->
            newdiv = document.createElement('div')
            newdiv.setAttribute('class', "ribbon go_to_page")
            newdiv.setAttribute('goto', value)
            newdiv.innerHTML = "<i><span>"+value+"</span></i>"
            $('#bookmark_'+yearbook_element_id).append(newdiv)
          )
    )
)